import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useNavigate } from "react-router";
import deleteIcon from "../assets/images/delete_policy.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React, { useContext, useEffect, useRef, useState } from "react";
import documentIcon from "../assets/images/policy_document-icon.svg";
import useOnClickOutside from "../hooks/useOnClickOutside";
import LoaderContext from "../context/LoaderContext";
import stepimg from ".././assets/images/our-promise-2.webp";
import AddIcon from "@mui/icons-material/Add";
import {
  canopyLoginPayloadTemplate,
  choicesInsuranceMarketplacePayloadTemplate,
  getInsuranceListPayloadTemplate,
  getMarkMarketplaceInsuranceListPayloadTemplate,
  getUserLeadListPayloadTemplate,
  getUserLoanProviderPayloadTemplate,
} from "../http/services/api-payload-prepare";
import {
  canopyLoginExternalSource,
  getInsuranceLists,
  getLeadDetails,
  getLeadList,
  insurancePreFillLead,
} from "../http/services/lead.service";
import { enqueueSnackbar } from "notistack";
import Home from "../assets/images/Home.svg";
import Auto from "../assets/images/Auto.svg";
import Condo from "../assets/images/Condo.svg";
import Renter from "../assets/images/Renter.svg";
import ActivityCard from "./ActivityCard";
import CommanBanner from "../comman/CommanBanner";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../assets/images/yolohblue.svg";
import { Controller, useForm } from "react-hook-form";
import InsurancesSelect from "./insurancesSelect";
import { includes } from "lodash";
import { handleInputBoxSpaceValidate } from "../validations";
import { useDispatch, useSelector } from "react-redux";
import { loaderEnd, loaderStart } from "../redux/slices/loader";
import andiIcon from "../assets/images/andiIcon.png";
import FilesDragAndDrop from "../comman/dragDrop/FilesDragAndDrop";
import { environment } from "../environment/environment";
const insuranceCarrierChoices = [
  "Bajaj Alliaz",
  "Iffco Tokio",
  "Reliance General Insurance",
  "HDFC Ergo",
  "Other",
];
const choices = [
  { label: "Better Coverage", value: "better coverages" },
  { label: "Better Rates", value: "better rates" },
  { label: "Both", value: "better coverages and better rates" },
];
const uploadPolicyDocument = ["application/pdf"];
const MAX_FILE_SIZE = 1050; // 10MB
const Insurances = () => {
  const [insuranceModel, setInsuranceModel] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadPolicyError, setUploadPolicyError] = useState("");
  const [selectType, setSelectType] = useState("");
  const [loginModel, setLoginModel] = useState(false);
  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const navigation = useNavigate();
  const [loanProviderDropdown, setLoanProviderDropdown] = useState({});
  const [loanProviderReferenceDropdown, setLoanProviderReferenceDropdown] =
    useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const [activityRecord, setActivityRecord] = useState({});
  const [leadPage, setLeadPage] = useState(20);
  const [insuranceList, setInsuranceList] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const isLoader = useSelector((state) => state?.loader?.isLoader);
  const [showUploadDoc, setUploadDoc] = useState(false);
  const [answer, setAnswer] = useState({});
  const [loadMoreLoader, setLoadMoreLoader] = useState(false);
  const {
    handleSubmit,
    setValue,
    setError,
    trigger,
    reset,
    clearErrors,
    control,
  } = useForm();

  const onclose = () => {
    setInsuranceModel(false);
  };
  // useEffect(() => {
  //   getInsuranceList();
  // }, []);
  useEffect(() => {
    const fetchData = async () => {
      // try {
      await dispatch(loaderStart());
      // await Promise.all([
      await getUserLeadList(0, 20),
        // await getUserLoanProviderOption(),
        await getInsuranceList(),
        // ]);
        // } catch (err) {
        // Handle errors if needed
        // } finally {
        // await setTimeout(() => {
        dispatch(loaderEnd());
      // }, 2000);
      // }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (location?.state === "Quotes received" && insuranceList?.length > 0) {
      const element = document?.getElementById("quotes_received_section");
      if (element?.offsetLeft && element?.offsetTop) {
        const count = insuranceList?.length > 10 ? 150 : 50;
        window?.scrollTo({
          left: element?.offsetLeft,
          top: element?.offsetTop + count,
          behavior: "smooth",
        });
      }
    }
  }, [location?.state, insuranceList]);
  const getUserLoanProviderOption = async () => {
    const fetchUserLoanProviderPayload = getUserLoanProviderPayloadTemplate();
    await getLeadDetails(fetchUserLoanProviderPayload)
      .then((data) => {
        setLoanProviderDropdown(data.lenders);
      })
      .catch((err) => {});
  };
  const getInsuranceList = async () => {
    const fetchInsuranceListPayload =
      getMarkMarketplaceInsuranceListPayloadTemplate();
    await getInsuranceLists(fetchInsuranceListPayload)
      .then((data) => {
        setInsuranceList(data?.insuranceTypes);
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong.!", {
          variant: "error",
        });
        onclose();
      });
  };
  // Modal 3rd Button Function
  const onSubmitInsuranceMarketplace = async (
    insurance_type,
    isGlobalLoader
  ) => {
    setLoading(true);
    if (isGlobalLoader) {
      await dispatch(loaderStart());
    }
    const preQuestion = {
      house_purchase_status:
        answer?.buy_house === "yes"
          ? "in_process"
          : answer?.buy_house === "no"
          ? "purchased"
          : "",
      here_for: answer?.choose ?? "",
      other_lender_name: answer?.lender_name ?? "",
      other_lender_website: answer?.lender_website ?? "",
    };
    const payload = {
      zip: answer?.post_code ?? "",
      city: answer?.city ?? "",
      state: answer?.state ?? "",
      status: "DRAFT",
      lead_type: "Customer Initiated",
      addrress_line1: answer?.address_line_1 ?? "",
      addrress_line2: answer?.address_line_2 ?? "",
      insurance_type: insurance_type ?? "",
      country: answer?.country ?? "",
      application_type:
        answer?.lender_provider === "yes"
          ? answer?.loan_provider === 0
            ? "MANUAL"
            : "LOAN_REFERENCE"
          : "MANUAL",
      lender_sequence_id: answer?.loan_provider ?? 0,
      loan_reference_number: answer?.loan_reference_number ?? "",
      lead_pre_questionnaire: JSON?.stringify(preQuestion),
    };
    const submitHomeInsurancePayload =
      choicesInsuranceMarketplacePayloadTemplate(payload);
    insurancePreFillLead(submitHomeInsurancePayload)
      .then((data) => {
        if (data && data?.sequence_id && data?.lead_details) {
          const { lead_details, sequence_id, message } = data;
          // enqueueSnackbar(message, { variant: "success" });
          onClose();
          navigation(`/insurance/${insurance_type}`, {
            state: {
              lead_details,
              sequenceId: sequence_id,
              insurance_type: insuranceList?.find(
                (item) => item?.id === insurance_type
              ),
            },
          });
        } else if (data && data?.error) {
          enqueueSnackbar(data?.error, { variant: "error" });
        } else {
          enqueueSnackbar("Something went wrong.!", { variant: "error" });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong.!", { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
        dispatch(loaderEnd());
      });
  };
  const getUserLeadList = async (start, limit) => {
    const fetchUserLeadListPayload = getUserLeadListPayloadTemplate({
      start,
      limit,
    });
    setLoadMoreLoader(true);
    await getLeadList(fetchUserLeadListPayload)
      .then((data) => {
        if (data.userLeadList) {
          if (activityRecord?.userLeadList?.length > 0) {
            setActivityRecord({
              ...data,
              userLeadList: [
                ...activityRecord?.userLeadList,
                ...data?.userLeadList,
              ],
            });
          } else {
            setActivityRecord({ ...data });
          }
        }
      })
      .catch((err) => {})
      .finally(() => setLoadMoreLoader(false));
  };
  const loadMoreLead = () => {
    getUserLeadList(activityRecord?.userLeadList?.length, 20);
  };
  const ref = useRef();
  useOnClickOutside(ref, () => (insuranceModel ? onclose() : null));
  const onHandelChange = (event) => {
    const { name, value } = event.target;
    if (name === "uploadDocument") {
      const file = event.target.files[0];
      if (uploadPolicyDocument?.includes(file?.type)) {
        const fileSizeKiloBytes = file?.size / 1024;
        if (fileSizeKiloBytes > MAX_FILE_SIZE) {
          setValue("uploadDocument", "");
          setAnswer({
            ...answer,
            uploadDocument: null,
          });
          setError("uploadDocument", {
            type: "manual",
            message: "Please upload less than 1MB file size",
          });
        } else {
          setValue("uploadDocument", event);
          clearErrors("uploadDocument");
          setAnswer((preAnswer) => {
            return { ...preAnswer, [name]: file };
          });
        }
      } else {
        setValue("uploadDocument", "");
        setAnswer({
          ...answer,
          uploadDocument: null,
        });
        setError("uploadDocument", {
          type: "manual",
          message: "Uploaded file must be .pdf format",
        });
      }
    } else {
      if (name === "combine_insurance") {
        if (value === "yes") {
          const changeInsurance =
            selectedType?.id === "home"
              ? insuranceList?.find((val) => val?.id === "autoandhome")
              : selectedType?.id === "condo"
              ? insuranceList?.find((val) => val?.id === "autoandcondo")
              : {};
          setSelectedType(changeInsurance);
        } else if (value === "no") {
          const changeInsurance =
            selectedType?.id === "autoandhome"
              ? insuranceList?.find((val) => val?.id === "home")
              : selectedType?.id === "autoandcondo"
              ? insuranceList?.find((val) => val?.id === "condo")
              : selectedType;
          setSelectedType(changeInsurance);
        }
      }
      setAnswer((preAnswer) => {
        return { ...preAnswer, [name]: value };
      });
    }
  };
  const onNextQuestion = async () => {
    if (activeQuestion === 1) {
      onSubmitInsuranceMarketplace(selectedType?.id);
    }
    // if (
    //   ["home", "condo", "autoandhome", "autoandcondo"].includes(
    //     selectedType?.id
    //   )
    // ) {
    //   if (activeQuestion === 7) {
    //     onSubmitInsuranceMarketplace(selectedType?.id);
    //   } else if (answer?.buy_house === "yes") {
    //     if (answer?.lender_provider === "yes" && activeQuestion === 4) {
    //       onSubmitInsuranceMarketplace(selectedType?.id);
    //     } else if (answer?.lender_provider === "no" && activeQuestion === 3) {
    //       setActiveQuestion(6);
    //       handelScroll("top");
    //     } else {
    //       setActiveQuestion((preActive) => preActive + 1);
    //       handelScroll("top");
    //     }
    //   } else if (answer?.buy_house === "no" && activeQuestion === 2) {
    //     setActiveQuestion(5);
    //     handelScroll("top");
    //   } else if (activeQuestion === 1) {
    //     onSubmitInsuranceMarketplace(selectedType?.id);
    //   } else {
    //     setActiveQuestion((preActive) => preActive + 1);
    //     handelScroll("top");
    //   }
    // } else {
    //   if (activeQuestion === 7) {
    //     onSubmitInsuranceMarketplace(selectedType?.id);
    //   } else {
    //     setActiveQuestion((preActive) => preActive + 1);
    //     handelScroll("top");
    //   }
    // }
  };
  const onBackQuestion = () => {
    if (showUploadDoc) {
      setUploadDoc(false);
      return;
    }
    if (
      ["home", "condo", "autoandhome", "autoandcondo"]?.includes(
        selectedType?.id
      )
    ) {
      if (
        answer?.buy_house === "yes" &&
        answer?.lender_provider === "no" &&
        activeQuestion === 6
      ) {
        setActiveQuestion(3);
      } else if (answer?.buy_house === "no" && activeQuestion === 5) {
        setActiveQuestion(2);
      } else {
        setActiveQuestion((preActive) => preActive - 1);
      }
    } else {
      setActiveQuestion((preActive) => preActive - 1);
    }
    handelScroll("top");
  };
  const onClose = () => {
    setOpen(false);
    setActiveQuestion(1);
    setAnswer({});
    setSelectType("");
    reset();
  };
  const validationLoanReference = (selectedValues) => {
    const trimmedValue = selectedValues.trim();
    if (trimmedValue.length < 1 || trimmedValue.length > 20) {
      return `${
        selectedValues.length > 20
          ? `Loan Reference Number must be 20 characters`
          : `Loan Reference Number is required!`
      }`;
    }
    return true;
  };
  const handelScroll = (position = false) => {
    const element = document.getElementById("model-insurance-marketplace");
    element.scrollTo({
      left: 0,
      top: position === "top" ? 0 : element?.offsetHeight + 50,
      behavior: "smooth",
    });
  };
  const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffffff",
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffffff",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffffff",
      },
      "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F06363 !important",
      },
      "& .MuiInputLabel-outlined.Mui-error": {
        color: "#F06363 !important",
      },
      "& .MuiOutlinedInput-input": {
        color: "#ffffff",
      },
      "& .MuiOutlinedInput-input.Mui-disabled": {
        WebkitTextFillColor: "#ffffff",
        opacity: "0.5",
      },
      "& .css-1avhp5v-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
        {
          borderColor: "#ffffff",
          opacity: "0.5",
        },
      "&:hover .MuiOutlinedInput-input": {
        color: "#ffffff",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        color: "#ffffff",
      },
      "& .MuiInputLabel-outlined": {
        // color: "#ffffff",
      },
      "&:hover .MuiInputLabel-outlined": {
        // color: "#ffffff",
      },
      "& .MuiInputLabel-outlined.Mui-focused": {
        color: "#ffffff",
      },

      "& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon": {
        color: "#ffffff",
      },
      "& .MuiSvgIcon-fontSizeSmall": {
        color: "#ffffff",
      },
      "& .css-1pchc3k-MuiFormLabel-root-MuiInputLabel-root.Mui-error": {
        color: "#F06363 !important",
      },
    },
  });
  const classes = useStyles();

  const onSubmitCanopyInsuranceLogin = async (pull_id) => {
    let insuranceType = selectedType?.id;
    const canopyLoginInsurancePayload = canopyLoginPayloadTemplate(
      pull_id,
      "voi",
      insuranceType
    );
    dispatch(loaderStart());
    canopyLoginExternalSource(canopyLoginInsurancePayload)
      .then((data) => {
        if (data && data?.code === 201 && data?.message) {
          setLoginModel(true);
        } else if (data && data?.error) {
          enqueueSnackbar(data?.error, { variant: "error" });
        } else {
          enqueueSnackbar("Something went wrong.!", { variant: "error" });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong.!", { variant: "error" });
      })
      .finally(() => {
        dispatch(loaderEnd());
      });
  };
  // Cannopy Modal Functionality
  const CanopyConnectBtn = ({ publicAlias }) => {
    const [handler, setHandler] = useState(null);
    useEffect(() => {
      if (!publicAlias) {
        return;
      }
      let canopyHandler = window?.CanopyConnect?.create({
        publicAlias,
      });
      setHandler(canopyHandler);
      canopyHandler.on("authenticationSuccess", (authResponse) => {
        dispatch(loaderStart());
        onSubmitCanopyInsuranceLogin(authResponse?.pull?.pull_id);
        onClose();
        canopyHandler.close();
      });
      return () => {
        setHandler(null);
        canopyHandler.destroy();
      };
    }, [publicAlias]);
    if (!handler) {
      return (
        <Button disabled size="lg" as="a">
          Loading....
        </Button>
      );
    }

    return (
      <button
        className="existingInsuranceLoginBtn"
        onClick={() => {
          handler.open();
        }}
      >
        Existing Insurance Login
      </button>
    );
  };

  return (
    <div className="container_width">
      <section className="mt-40 imageIcon">
        <CommanBanner
          title="Meet Andi!"
          description="Your genius personal Shopper. No need to send your current policies to retrieve quotes!"
          image={andiIcon}
          steps={[
            { title: "Choose your Insurance" },
            { title: "Fill in the Details" },
            { title: "Receive Quick Quotes" },
          ]}
          collapsable={true}
        />
      </section>
      {insuranceList?.length > 0 ? (
        <section>
          <hr style={{ margin: "40px 0px", opacity: 0.3 }} />
          <h2 className="overview_title mt-40">Choose your Insurance</h2>
          <div className="insurance_flex_box">
            {insuranceList &&
              insuranceList?.length > 0 &&
              insuranceList?.map((item, index) => (
                <div
                  key={index}
                  onMouseEnter={() => setSelectedInsurance(item?.id)}
                  onMouseLeave={() => setSelectedInsurance(null)}
                  className={`insurance_box`}
                  onClick={() => {
                    if (["home"].includes(item?.id)) {
                      setOpen(true);
                      setSelectedType(item);
                      setActiveQuestion(1);
                    }
                    // else if (["autoandhome"].includes(item?.id)) {
                    //   setOpen(true);
                    //   setSelectedType(item);
                    //   setActiveQuestion(1);
                    // }
                    else {
                      onSubmitInsuranceMarketplace(item?.id, true);
                    }
                  }}
                >
                  <div
                    className={
                      selectedInsurance === item?.id ? "policy-selected" : ""
                    }
                  >
                    <img src={item?.icon} alt="home" />
                  </div>
                  <p>{item?.title}</p>
                </div>
              ))}
          </div>
        </section>
      ) : null}
      <div id="quotes_received_section">
        <hr style={{ margin: "40px 0px" }} />
        <div className="flex_box my-30">
          <h2 className="overview_title">Your Active Quotes</h2>
        </div>
      </div>
      <ActivityCard
        activityRecord={activityRecord?.userLeadList ?? []}
        loadMoreLead={loadMoreLead}
        loadMoreLoader={loadMoreLoader}
        total={activityRecord?.total}
      />
      <Modal
        open={open}
        keepMounted={!loading}
        onClose={!loading ? onClose : null}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={
          activeQuestion === 2 ? "main_modal_bg secondpopup" : "main_modal_bg"
        }
      >
        <div className={`main_modal model-contain-main-upload`}>
          {/* <img src={logo} alt="" className="modal_logo" /> */}
          <div className="modal_bg bg-dark-blue">
            <div className={`modal_header justify-between align-items-center`}>
              <div className="cursor-pointer">
                {["home", "condo", "autoandhome", "autoandcondo"].includes(
                  selectedType?.id
                ) ? (
                  activeQuestion > 1 ? (
                    <ArrowBackIosIcon onClick={onBackQuestion} />
                  ) : (
                    <div></div>
                  )
                ) : activeQuestion > 5 ? (
                  <ArrowBackIosIcon onClick={onBackQuestion} />
                ) : (
                  <div></div>
                )}
              </div>
              {/* ${
                  selectedType?.id.includes("and") ? "combine_modal_tag" : ""
                } */}
              <div className={`insurance_modal_tag`}>
                <img src={logo} alt="" className="modal_logo" />
                {/* <img src={selectedType?.icon} alt={selectedType?.title} /> */}
                {/* <span>{selectedType?.title}</span> */}
              </div>
              <div className="cursor-pointer">
                <CloseIcon onClick={onClose} />
              </div>
            </div>
            <div className="p-40 model-contain-main-responsive model-contain-main-responsive-upload">
              <div
                className="model-contain model-contain-res"
                id="model-insurance-marketplace"
              >
                {/* {["home", "condo", "autoandhome", "autoandcondo"]?.includes(
                  selectedType?.id
                ) ? (
                  <> */}
                {activeQuestion === 1 && (
                  <Controller
                    name="combine_insurance"
                    control={control}
                    rules={{ required: "this field is required!" }}
                    defaultValue={answer?.combine_insurance ?? ""}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <h1
                          id="modal-modal-title"
                          className="white-color text-center"
                        >
                          {`Hey, do you want to combine ${
                            selectedType?.id === "home"
                              ? "Home and Auto"
                              : selectedType?.id === "condo"
                              ? "Auto and Condo"
                              : selectedType?.id === "autoandhome"
                              ? "Home and Auto"
                              : selectedType?.id === "autoandcondo"
                              ? "Auto and Condo"
                              : selectedType?.title
                          } 
                            Insurance? It could lead to substantial savings.`}
                        </h1>
                        <div className="question-contain justify-center">
                          <div className="survey_card_header">
                            <input
                              id="applicable_yes"
                              type="radio"
                              value="yes"
                              name="combine_insurance"
                              checked={answer?.combine_insurance === "yes"}
                              className="survey_option_radio white-border bg-dark-blue"
                              onChange={(e) => {
                                onHandelChange(e);
                                onChange(e);
                              }}
                            />
                            <label
                              htmlFor="applicable_yes"
                              className="white-color"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="survey_card_header">
                            <input
                              id="applicable_no"
                              type="radio"
                              value="no"
                              checked={answer?.combine_insurance === "no"}
                              name="combine_insurance"
                              className="survey_option_radio white-border bg-dark-blue"
                              onChange={(e) => {
                                onHandelChange(e);
                                onChange(e);
                              }}
                            />
                            <label
                              htmlFor="applicable_no"
                              className="white-color"
                            >
                              No
                            </label>
                          </div>
                        </div>
                        <FormHelperText error={!!error} variant="outlined">
                          <span>{error?.message}</span>
                        </FormHelperText>
                      </>
                    )}
                  />
                )}
                {activeQuestion === 2 &&
                  (showUploadDoc ? (
                    <>
                      <h1
                        id="modal-modal-title"
                        className="white-color text-center"
                      >
                        Upload your Policy
                      </h1>
                      {answer?.uploadDocument?.name ? (
                        <div className="upload_policy bg-white">
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <img alt="document-icon" src={documentIcon} />
                            <span
                              className="file-link"
                              style={{
                                fontFamily: "jokker-medium",
                                fontSize: "18px",
                              }}
                            >
                              {answer?.uploadDocument?.name}
                            </span>
                          </div>

                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              setAnswer((pre) => {
                                return { ...pre, uploadDocument: null };
                              });
                            }}
                          >
                            <img alt="delete-icon" src={deleteIcon} />
                          </div>
                        </div>
                      ) : (
                        <>
                          <FilesDragAndDrop
                            onUpload={(files) => {
                              if (uploadPolicyDocument?.includes(files?.type)) {
                                const fileSizeKiloBytes = files?.size / 1024;
                                if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                                  setAnswer((pre) => {
                                    return { ...pre, uploadDocument: null };
                                  });
                                  setUploadPolicyError(
                                    "Please upload less than 1MB file size"
                                  );
                                } else {
                                  setUploadPolicyError("");
                                  setAnswer((preAnswer) => {
                                    return {
                                      ...preAnswer,
                                      uploadDocument: files,
                                    };
                                  });
                                }
                              } else {
                                setAnswer((pre) => {
                                  return { ...pre, uploadDocument: null };
                                });
                                setUploadPolicyError(
                                  "Uploaded file must be .pdf format"
                                );
                              }
                            }}
                            count={1}
                            name={"uploadDocument"}
                            formats={["pdf"]}
                            defaultFile={""}
                            message={uploadPolicyError}
                            setMessage={setUploadPolicyError}
                            style={{
                              minHeight: "87px",
                              borderRadius: "12px",
                              flexDirection: "row",
                              gap: "10px",
                              marginTop: "40px",
                              borderColor: "#fff",
                              color: "#fff",
                            }}
                            accept="application/pdf"
                          />
                          <FormHelperText
                            className="doc_error"
                            error={!!uploadPolicyError?.text}
                            variant="outlined"
                          >
                            {uploadPolicyError?.text && (
                              <InfoOutlinedIcon color="error" size="small" />
                            )}
                            <span>{uploadPolicyError?.text}</span>
                          </FormHelperText>
                        </>
                      )}
                      <button
                        className="existingInsuranceLoginBtn mt-40 w-100"
                        disabled={!answer?.uploadDocument?.name || loading}
                        onClick={() =>
                          onSubmitInsuranceMarketplace(selectedType?.id)
                        }
                      >
                        Import Policy
                      </button>
                      <p className="white-color text-align-center">
                    I am solely responsible for the information and documents I
                    provide, YOLOH is not liable for any errors, frauds or
                    misrepresentations.
                  </p>
                    </>
                  ) : (
                    <>
                      <p id="modal-modal-title" className="mainPopup">
                        If you have an existing policy select <br /> your
                        carrier from our list
                      </p>
                      <CanopyConnectBtn
                        publicAlias={
                          environment?.canopy_connect?.canopy_public_alias
                        }
                      />
                      <div className="dividerMainPopup">
                        <div>OR</div>
                      </div>
                      <p
                        id="modal-modal-title"
                        className="white-color mainPopup"
                      >
                        If your carrier is not present on our list you <br />{" "}
                        may upload your policy
                      </p>
                      <button
                        className="existingInsuranceLoginBtn"
                        onClick={() => setUploadDoc(true)}
                      >
                        Upload Policy
                      </button>
                      <div className="dividerMainPopup">
                        <div>OR</div>
                      </div>
                      <p
                        id="modal-modal-title"
                        className="white-color mainPopup"
                      >
                        If you don't have an insurance
                      </p>
                      <button
                        className="existingInsuranceLoginBtn"
                        onClick={() =>
                          onSubmitInsuranceMarketplace(selectedType?.id)
                        }
                        disabled={loading}
                      >
                        Shop for New Policy{" "}
                        {loading && (
                          <>
                            <CircularProgress color="inherit" size={20} />
                          </>
                        )}
                      </button>
                      {/* <div className="question-contain">
                      <div className="survey_card_header">
                        <input
                          id="applicable_no"
                          type="radio"
                          value="yes"
                          name="buy_house"
                          checked={answer?.buy_house === "yes"}
                          className="survey_option_radio white-border bg-dark-blue"
                          onChange={(e) => {
                            onHandelChange(e);
                            onChange(e);
                          }}
                        />
                        <label
                          htmlFor="applicable_no"
                          className="white-color"
                        >
                          Yes, in the process
                        </label>
                      </div>
                      <div className="survey_card_header">
                        <input
                          id="applicable_yes"
                          type="radio"
                          value="no"
                          name="buy_house"
                          checked={answer?.buy_house === "no"}
                          className="survey_option_radio white-border bg-dark-blue"
                          onChange={(e) => {
                            onHandelChange(e);
                            onChange(e);
                          }}
                        />
                        <label
                          htmlFor="applicable_yes"
                          className="white-color"
                        >
                          Already Purchased
                        </label>
                      </div>
                    </div> */}
                      {/* <FormHelperText error={!!error} variant="outlined">
                      <span>{error?.message}</span>
                    </FormHelperText> */}
                    </>
                  ))}
                {/* {answer?.buy_house === "yes" ? (
                      <>
                        {activeQuestion === 3 && (
                          <Controller
                            name="lender_provider"
                            control={control}
                            rules={{ required: "this field is required!" }}
                            defaultValue={answer?.lender_provider ?? ""}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <>
                                <h1
                                  id="modal-modal-title"
                                  className="white-color"
                                >
                                  Are you working with a lender/ mortgage
                                  provider?
                                </h1>
                                <div className="question-contain">
                                  <div className="survey_card_header">
                                    <input
                                      id="applicable_yes"
                                      type="radio"
                                      value="yes"
                                      checked={
                                        answer?.lender_provider === "yes"
                                      }
                                      name="lender_provider"
                                      className="survey_option_radio white-border bg-dark-blue"
                                      onChange={(e) => {
                                        onHandelChange(e);
                                        onChange(e);
                                      }}
                                    />
                                    <label
                                      htmlFor="applicable_yes"
                                      className="white-color"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="survey_card_header">
                                    <input
                                      id="applicable_no"
                                      type="radio"
                                      value="no"
                                      checked={answer?.lender_provider === "no"}
                                      name="lender_provider"
                                      className="survey_option_radio white-border bg-dark-blue"
                                      onChange={(e) => {
                                        onHandelChange(e);
                                        onChange(e);
                                      }}
                                    />
                                    <label
                                      htmlFor="applicable_no"
                                      className="white-color"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                                <FormHelperText
                                  error={!!error}
                                  variant="outlined"
                                >
                                  <span>{error?.message}</span>
                                </FormHelperText>
                              </>
                            )}
                          />
                        )}

                        {answer?.lender_provider === "yes" &&
                          activeQuestion === 4 && (
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 10,
                                }}
                              >
                                <Controller
                                  name="loan_provider"
                                  control={control}
                                  rules={{
                                    required: "this field is required!",
                                  }}
                                  defaultValue={answer?.loan_provider ?? ""}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => (
                                    <>
                                      <h1
                                        id="modal-modal-title"
                                        className="white-color"
                                      >
                                        We work with multiple lenders and we can
                                        pull your details if you select one of
                                        our lenders
                                      </h1>
                                      <>
                                        <FormControl
                                          fullWidth
                                          className={classes.root}
                                        >
                                          <InputLabel error={!!error}>
                                            Lender
                                          </InputLabel>
                                          <Select
                                            label={"Lender"}
                                            placeholder={"Lender"}
                                            name="loan_provider"
                                            color="white"
                                            value={value}
                                            error={!!error}
                                            onChange={(event) => {
                                              onHandelChange(event);
                                              onChange(event);
                                              setValue(
                                                "loan_reference_number",
                                                ""
                                              );
                                              const findLoanReferenceNumber =
                                                loanProviderDropdown?.find(
                                                  (item) =>
                                                    item?.sequence_id ===
                                                    event?.target?.value
                                                );
                                              if (
                                                findLoanReferenceNumber
                                                  ?.loan_reference_numbers
                                                  ?.length > 0
                                              ) {
                                                setLoanProviderReferenceDropdown(
                                                  findLoanReferenceNumber?.loan_reference_numbers
                                                );
                                              }
                                            }}
                                            inputProps={{
                                              classes: {
                                                focused: "input-focused",
                                                root: "input-root",
                                              },
                                            }}
                                          >
                                            {loanProviderDropdown &&
                                              loanProviderDropdown?.length >
                                                0 &&
                                              loanProviderDropdown?.map(
                                                (type, i) => (
                                                  <MenuItem
                                                    value={type?.sequence_id}
                                                    key={i}
                                                  >
                                                    {type?.name}
                                                  </MenuItem>
                                                )
                                              )}
                                          </Select>
                                        </FormControl>
                                        <FormHelperText
                                          error={!!error}
                                          variant="outlined"
                                        >
                                          <span>{error?.message}</span>
                                        </FormHelperText>
                                      </>
                                    </>
                                  )}
                                />
                                {answer?.loan_provider !== 0 && (
                                  <Controller
                                    key="loan_reference_number"
                                    name="loan_reference_number"
                                    control={control}
                                    rules={{
                                      validate: (value) =>
                                        validationLoanReference(value),
                                    }}
                                    defaultValue={
                                      answer?.loan_reference_number ?? ""
                                    }
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <>
                                        <FormControl
                                          fullWidth
                                          className={classes.root}
                                        >
                                          <InputLabel error={!!error}>
                                            Loan Reference Number
                                          </InputLabel>
                                          <Select
                                            label={"Loan Reference Number"}
                                            placeholder={
                                              "Loan Reference Number"
                                            }
                                            name="loan_reference_number"
                                            color="white"
                                            value={value}
                                            error={!!error}
                                            onChange={(event) => {
                                              onHandelChange(event);
                                              onChange(event);
                                            }}
                                            inputProps={{
                                              classes: {
                                                focused: "input-focused",
                                                root: "input-root",
                                              },
                                            }}
                                          >
                                            {loanProviderReferenceDropdown &&
                                              loanProviderReferenceDropdown?.length &&
                                              loanProviderReferenceDropdown?.map(
                                                (type, i) => (
                                                  <MenuItem
                                                    value={type}
                                                    key={i}
                                                  >
                                                    {type}
                                                  </MenuItem>
                                                )
                                              )}
                                          </Select>
                                        </FormControl>
                                        <FormHelperText
                                          error={!!error}
                                          variant="outlined"
                                        >
                                          <span>{error?.message}</span>
                                        </FormHelperText>
                                      </>
                                    )}
                                  />
                                )}
                              </div>
                              {answer?.loan_provider === 0 && (
                                <>
                                  <Controller
                                    key="lender_name"
                                    name="lender_name"
                                    control={control}
                                    rules={{
                                      required: "Lender Name is required!",
                                      validate: (value) =>
                                        handleInputBoxSpaceValidate(value),
                                    }}
                                    defaultValue={answer?.lender_name ?? ""}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <>
                                        <TextField
                                          id="lender_name"
                                          name="lender_name"
                                          label="Lender Name"
                                          className={classes.root}
                                          variant="outlined"
                                          margin="normal"
                                          color="white"
                                          type="text"
                                          placeholder="Lender Name"
                                          fullWidth
                                          InputLabelProps={{
                                            classes: {
                                              focused: "input-focused",
                                              root: "input-root",
                                            },
                                          }}
                                          onChange={(event) => {
                                            onChange(event);
                                            onHandelChange(event);
                                          }}
                                          helperText={
                                            error ? error.message : null
                                          }
                                          value={value}
                                          error={!!error}
                                        />
                                      </>
                                    )}
                                  />
                                  <Controller
                                    key="lender_website"
                                    name="lender_website"
                                    control={control}
                                    rules={{
                                      required: "Lender Website is required!",
                                      validate: (value) =>
                                        handleInputBoxSpaceValidate(value),
                                    }}
                                    defaultValue={answer?.lender_website ?? ""}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <>
                                        <TextField
                                          id="lender_website"
                                          name="lender_website"
                                          label="Lender Website"
                                          variant="outlined"
                                          margin="normal"
                                          color="white"
                                          className={classes.root}
                                          type="text"
                                          placeholder="Lender Website"
                                          fullWidth
                                          InputLabelProps={{
                                            classes: {
                                              focused: "input-focused",
                                              root: "input-root",
                                            },
                                          }}
                                          helperText={
                                            error ? error.message : null
                                          }
                                          onChange={(event) => {
                                            onChange(event);
                                            onHandelChange(event);
                                          }}
                                          value={value}
                                          error={!!error}
                                        />
                                      </>
                                    )}
                                  />
                                </>
                              )}
                            </div>
                          )}
                        <InsurancesSelect
                          selectedType={selectedType}
                          onHandelChange={onHandelChange}
                          answer={answer}
                          control={control}
                          activeQuestion={activeQuestion}
                          classes={classes}
                          setAnswer={setAnswer}
                          setValue={setValue}
                          clearErrors={clearErrors}
                          selectType={selectType}
                          setSelectType={setSelectType}
                          onClose={onClose}
                          setLoginModel={setLoginModel}
                        />
                      </>
                    ) : answer?.buy_house === "no" ? (
                      <InsurancesSelect
                        selectedType={selectedType}
                        onHandelChange={onHandelChange}
                        answer={answer}
                        control={control}
                        activeQuestion={activeQuestion}
                        setAnswer={setAnswer}
                        classes={classes}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        selectType={selectType}
                        setSelectType={setSelectType}
                        onClose={onClose}
                        setLoginModel={setLoginModel}
                      />
                    ) : null} */}
                {/* </>
                ) : (
                  <InsurancesSelect
                    selectedType={selectedType}
                    onHandelChange={onHandelChange}
                    answer={answer}
                    control={control}
                    activeQuestion={activeQuestion}
                    setAnswer={setAnswer}
                    classes={classes}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    selectType={selectType}
                    setSelectType={setSelectType}
                    onClose={onClose}
                    setLoginModel={setLoginModel}
                  />
                )} */}
              </div>
              {activeQuestion !== 2 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "40px",
                    // paddingRight: "40px",
                  }}
                >
                  <Button
                    className="view_button"
                    disabled={loading}
                    style={{ width: "200px", height: 60 }}
                    onClick={handleSubmit(async (val) => {
                      onNextQuestion();
                    })}
                  >
                    {loading && (
                      <>
                        <CircularProgress color="inherit" size={20} />
                        &nbsp; &nbsp;
                      </>
                    )}
                    Continue
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={loginModel}
        onClose={() => setLoginModel(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="main_modal_bg"
      >
        <div className="main_modal">
          <div className="modal_bg bg-dark-blue">
            <div
              style={{ padding: "30px" }}
              className={`modal_header justify-end`}
            >
              <img src={logo} alt="" className="modal_logo" />
            </div>
            <div className="p-40 model-contain-main-responsive">
              <div className="model-contain" id="model-insurance-marketplace">
                <h2 className="login-link-title white-color">
                  Sit back and relax!
                </h2>
                <p className="login-link-message white-color">
                  We are capturing your policy details. We will update you as
                  soon as we complete the process.
                </p>
                <div style={{ textAlign: "center", marginTop: "40px" }}>
                  <Button
                    className="view_button"
                    style={{ width: "105px", height: "61px" }}
                    onClick={() => setLoginModel(false)}
                  >
                    Ok
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Insurances;
