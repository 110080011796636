import {
  Autocomplete,
  CircularProgress,
  FormHelperText,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router";
import OtpImg from "../assets/images/Yoloh_Yolo_transparent.png";
import createLogo from "../assets/images/logo.svg";
import React, { useCallback, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Controller, useForm } from "react-hook-form";
import {
  createAccountPayloadTemplate,
  getProfileDetailsPayloadTemplate,
} from "../http/services/api-payload-prepare";
import {
  createAccountApi,
  getProfileDetailsApi,
} from "../http/services/user.service";
import { useLocalStorage } from "../http/services/local-storage";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  fetchGooglePlaceDetails,
  fetchGooglePlaces,
} from "../http/services/map.service";
import * as _ from "lodash";
import { useAuth } from "../context/AuthContext";
import { enqueueSnackbar } from "notistack";
import { handleInputBoxSpaceValidate } from "../validations";
import { Troubleshoot } from "@mui/icons-material";
import {
  getAuthUser,
  getAuthUserValidated,
  useLocalStorageService,
} from "../http/services/local-storage-service";
// import { createUserWithEmailAndPassword } from "firebase/auth";
// import { auth } from "./Chats/firebase";

const CreateAccount = () => {
  const navigate = useNavigate();
  const { authUser, setAuthUser, isLoggedIn } = useAuth();
  const navigateDashboard = () => {
    navigate("/overview");
  };
  const { setLocalStorageRecord } = useLocalStorageService();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [error, setError] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);

  let defaultPlaceFormData = {
    city: "",
    state: "",
    address: "",
    postal_code: "",
    country: "",
  };

  const [placeFormData, setPlaceFormData] = useState(defaultPlaceFormData);

  const { handleSubmit, trigger, reset, control /*formState: {errors}*/ } =
    useForm();
  const { getItem, setItem } = useLocalStorage();

  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [autoCompleteLoading, setAutoCompleteLoading] = useState(false);
  const [autoCompleteSelected, setAutoCompleteSelected] = useState("");

  const fetchPlaces = async (value) => {
    setAutoCompleteLoading(true);
    let places = await fetchGooglePlaces(value);
    setAutoCompleteOptions(places);
    setAutoCompleteLoading(false);
  };
  const handlePlaceDetails = async (newInputValue, reason) => {
    let currentSelectedPlace = autoCompleteOptions.find(
      (item) => item.description === newInputValue
    );
    if (currentSelectedPlace) {
      setAutoCompleteLoading(true);
      setAutoCompleteSelected(
        currentSelectedPlace?.structured_formatting?.main_text
      );
      let placeData = await fetchGooglePlaceDetails(
        currentSelectedPlace.place_id
      );
      setAutoCompleteLoading(false);
      let data = {
        address: currentSelectedPlace?.structured_formatting?.main_text,
        city: placeData.city,
        state: placeData.state,
        postal_code: placeData.zip,
        country: placeData.country,
      };
      setPlaceFormData(data);
      setAutoCompleteLoading(false);
      return placeData;
    } else if (!newInputValue && reason == "clear") {
      setAutoCompleteSelected("");
      setAutoCompleteOptions([]);
    }
  };

  const debouncedFetchPlaces = useCallback(
    _.debounce((value) => {
      fetchPlaces(value);
    }, 100),
    []
  );
  
  return (
    <Modal
      keepMounted={false}
      open={
        authUser?.authUserValidated === false ||
        getAuthUserValidated() === false
          ? true
          : false
      }
      // onClose={closeLoginModal}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box>
        <div className="createAccount">
          <div className="d-flex align-items-center w-100">
            <div className="image-section w-50">
              <img
                width="240px"
                alt="Angular Logo"
                className="logo-create"
                src={createLogo}
              />
              <img alt="Angular Logo" className="illu-create" src={OtpImg} />
            </div>
            <div className="form-section w-50 create-acc-res">
              <form>
                <div className="create-heading">
                  <h1>Create Account</h1>
                </div>
                <div className="create-field-main">
                  <div className="create-field">
                    <Controller
                      name="first_name"
                      control={control}
                      rules={{
                        required: "First name is required!",
                        validate: (value) => handleInputBoxSpaceValidate(value),
                      }}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          style={{ margin: 0 }}
                          id="first_name"
                          name="first_name"
                          label="First Name"
                          variant="outlined"
                          margin="normal"
                          color="secondary"
                          type="text"
                          value={value}
                          error={!!error}
                          placeholder="Enter your first name"
                          onChange={(event) => {
                            onChange(event);
                            setFirstName(event.target.value);
                          }}
                          fullWidth
                          InputLabelProps={{
                            classes: { focused: "hello-world", root: "world" },
                          }}
                          helperText={error ? error.message : null}
                        />
                      )}
                    />
                  </div>
                  <div className="create-field">
                    <Controller
                      name="last_name"
                      control={control}
                      rules={{
                        required: "Last name is required!",
                        validate: (value) => handleInputBoxSpaceValidate(value),
                      }}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          style={{ margin: 0 }}
                          id="last_name"
                          name="last_name"
                          label="Last Name"
                          variant="outlined"
                          margin="normal"
                          color="secondary"
                          type="text"
                          value={value}
                          error={!!error}
                          placeholder="Enter your last name"
                          onChange={(event) => {
                            onChange(event);
                            setLastName(event.target.value);
                          }}
                          fullWidth
                          InputLabelProps={{
                            classes: { focused: "hello-world", root: "world" },
                          }}
                          helperText={error ? error.message : null}
                        />
                      )}
                    />
                  </div>
                  <div className="create-field">
                    <Controller
                      name="dob"
                      control={control}
                      rules={{
                        required: "Date of birth is required!",
                        validate: (value) => {
                          return dayjs(value, "YYYY-MM-DD").isBefore(dayjs()) &&
                            dayjs(value, "YYYY-MM-DD").isValid()
                            ? null
                            : "Enter valid Date of birth!";
                        },
                      }}
                      defaultValue={() => {
                        if (dob) {
                          return dayjs(dob, "YYYY-MM-DD");
                        }
                        return undefined;
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            id={"dateField_dob"}
                            autoComplete="nope"
                            name="dob"
                            disableFuture
                            label="Date of birth"
                            variant="outlined"
                            margin="none"
                            // value={value}
                            format="MM/DD/YYYY"
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                error: !!error,
                                name: "dob",
                              },
                            }}
                            placeholder="Enter your Date of birth"
                            onChange={(value) => {
                              onChange(value);
                              setDob(dayjs(value).format("YYYY-MM-DD"));
                            }}
                            inputProps={{
                              classes: {
                                focused: "hello-world",
                                root: "world",
                              },
                            }}
                            emptyLabel="custom label"
                            views={["year", "month", "day"]}
                          />
                          <FormHelperText error={!!error} variant="outlined">
                            <span>{error?.message}</span>
                          </FormHelperText>
                          {/*<FormHelperText error={!!error} variant="outlined"><span>{JSON.stringify(error)}</span></FormHelperText>*/}
                        </LocalizationProvider>
                      )}
                    />
                  </div>
                  <div className="create-field">
                    <Controller
                      control={control}
                      name="autocomplete_11"
                      rules={{
                        required: "Street Address is required!",
                        validate: (value) => handleInputBoxSpaceValidate(value),
                      }}
                      defaultValue={autoCompleteSelected ?? ""}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          id={"autocomplete_" + 11}
                          name={"autocomplete_" + 11}
                          fullWidth
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.description
                          }
                          freeSolo
                          defaultValue={autoCompleteSelected}
                          filterOptions={(x) => x}
                          options={autoCompleteOptions}
                          autoComplete
                          includeInputInList
                          filterSelectedOptions
                          value={autoCompleteSelected}
                          onChange={(event, newValue, reason) => {
                            // setAutoCompleteOptions(newValue ? [newValue, ...autoCompleteOptions] : autoCompleteOptions);
                            if (reason === "clear") {
                              setPlaceFormData(defaultPlaceFormData);
                            }
                            setAutoCompleteOptions(autoCompleteOptions);
                            // setAutoCompleteSelected(newValue);
                          }}
                          onInputChange={(event, newInputValue, reason) => {
                            // setAutoCompleteSelected(newInputValue);
                            onChange(newInputValue);
                            handlePlaceDetails(newInputValue, reason);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={!!error}
                              autoComplete="nope"
                              // defaultValue={autoCompleteSelected}
                              label="Street Address"
                              // value={value}
                              onChange={async (event) => {
                                debouncedFetchPlaces(event.target.value);
                              }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {autoCompleteLoading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                              helperText={error ? error.message : null}
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                </div>
                {error && <p className="error_block">{error}</p>}
                {requestInProgress && (
                  <p className="please_wait_block">
                    Please wait &nbsp; &nbsp;{" "}
                    <CircularProgress color="inherit" size={30} />
                  </p>
                )}
                {!requestInProgress && (
                  <button
                    className="button-primary align-right w-100"
                    type="submit"
                    onClick={handleSubmit(() => {
                      const formData = {
                        first_name: firstName,
                        last_name: lastName,
                        dob: dob,
                        email: "",
                        phone_number: "",
                        ...placeFormData,
                      };
                      let cognitoData = getAuthUser();
                      if (cognitoData) {
                        cognitoData = JSON.parse(cognitoData);
                        formData.email = cognitoData.attributes?.email;
                        formData.phone_number =
                          cognitoData.attributes?.phone_number;
                      }
                      const payload = createAccountPayloadTemplate(formData);
                      setRequestInProgress(true);
                      createAccountApi(payload)
                        .then(async (res) => {
                          if (res && res.code == 201) {
                            setLocalStorageRecord("authUserValidated", true);
                            const profilePayload =
                              getProfileDetailsPayloadTemplate();
                            let validateUser = await getProfileDetailsApi(
                              profilePayload
                            );
                            if (validateUser.code === 209) {
                              setLocalStorageRecord(
                                "authUserProfile",
                                JSON.stringify(validateUser.user)
                              );
                            }
                            // const createFireBaseAccount =
                            //   await createUserWithEmailAndPassword(
                            //     auth,
                            //     `${validateUser?.user?.sequence_id}@firebase.com`,
                            //     `${validateUser?.user?.email?.slice(
                            //       0,
                            //       5
                            //     )}${validateUser?.user?.phone_number?.slice(
                            //       0,
                            //       5
                            //     )}`
                            //   );
                            // console.log(
                            //   "createFireBaseAccount",
                            //   createFireBaseAccount
                            // );
                            enqueueSnackbar(`Account created.!`, {
                              variant: "success",
                            });
                            setAuthUser({
                              ...authUser,
                              authUserProfile: validateUser?.user,
                              authUserValidated: true,
                            });
                            navigate("/overview");
                            reset();
                            setAutoCompleteSelected("");
                          } else if (res && res.error) {
                            setError(res.error);
                            enqueueSnackbar(res.error, {
                              variant: "error",
                            });
                            setTimeout(() => {
                              setError(null);
                            }, 3000);
                          } else {
                            enqueueSnackbar("Something went wrong.!", {
                              variant: "error",
                            });
                          }
                        })
                        .catch((err) => {
                          setError(err.message);
                          enqueueSnackbar(err.message, {
                            variant: "error",
                          });
                          setTimeout(() => {
                            setError(null);
                          }, 3000);
                        })
                        .finally(() => setRequestInProgress(false));
                    })}
                  >
                    Submit
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default CreateAccount;
