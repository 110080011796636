import { getAuthCognitoId } from "./local-storage-service";

export const getDashboardStatsPayloadTemplate = () => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "user_dashboard-R",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      cognito_user_id: getAuthCognitoId(),
    },
  };
};

export const getUserLeadListPayloadTemplate = ({ start, limit }) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "user_lead_list-R",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      cognito_user_id: getAuthCognitoId(),
      start,
      limit,
    },
  };
};

export const getUserLeadStoreOrUpdatePayloadTemplate = (
  id,
  insuranceType,
  leadDetails,
  otherData
) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "user_lead-U",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      sequence_id: id || 0,
      cognito_user_id: getAuthCognitoId(),
      insurance_type: insuranceType,
      city: otherData.City,
      state: otherData.State,
      status: otherData.status,
      zip: otherData["Zip Code"],
      lead_details: leadDetails,
    },
  };
};

export const getUserLeadDetailsPayloadTemplate = (id) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "user_lead-R",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      sequence_id: Number(id),
      cognito_user_id: getAuthCognitoId(),
    },
  };
};

export const getAssignedAgentListPayloadTemplate = (sequence_id) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "user_lead_assigned_agents-R",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      lead_id: sequence_id,
      cognito_user_id: getAuthCognitoId(),
    },
  };
};
export const getAssignedAgentWiseListPayloadTemplate = (
  sequence_id,
  agent_profile_sequence_id
) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "agent_assigned_lead_quotes-R",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      lead_id: sequence_id,
      agent_profile_sequence_id: agent_profile_sequence_id,
      cognito_user_id: getAuthCognitoId(),
    },
  };
};

export const getAgentQuoteListPayloadTemplate = (
  leadSequenceId,
  agentProfileId
) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "view_lead_agent_quote-R",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      lead_sequence_id: leadSequenceId,
      agent_profile_sequence_id: agentProfileId,
      cognito_user_id: getAuthCognitoId(),
    },
  };
};

export const getAcceptQuotePayloadTemplate = (
  quoteId,
  agent_profile_sequence_id,
  lead_sequence_id,
  user_profile_sequence_id,
  insurance_type
) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "submit_quote-A",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      quote_sequence_id: quoteId,
      agent_profile_sequence_id: agent_profile_sequence_id,
      lead_sequence_id: lead_sequence_id,
      user_profile_sequence_id: user_profile_sequence_id,
      insurance_type,
    },
  };
};

export const createAccountPayloadTemplate = (formData) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "user_profile-C",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      cognito_user_id: getAuthCognitoId(),
      dob: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      has_kids: "",
      marital_status: "",
      has_pets: "",
      assets_owned: "",
      policies_owned: "",
      ...formData,
    },
  };
};

export const getProfileDetailsPayloadTemplate = () => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "user_profile-R",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      cognito_user_id: getAuthCognitoId(),
    },
  };
};

export const updateProfileDetailsPayloadTemplate = (formData) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "user_profile-U",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      cognito_user_id: getAuthCognitoId(),
      dob: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      has_kids: "",
      marital_status: "",
      has_pets: "",
      assets_owned: "",
      policies_owned: "",
      ...formData,
    },
  };
};

export const getInsuranceListPayloadTemplate = () => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "insurance_type-R",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      cognito_user_id: getAuthCognitoId(),
    },
  };
};

export const getMarkMarketplaceInsuranceListPayloadTemplate = () => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "find_insurances_types-R",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      cognito_user_id: getAuthCognitoId(),
    },
  };
};

export const getInsurancePayloadTemplate = (type) => {
  return {
    body: {
      insurance_type: type,
      cognito_user_id: getAuthCognitoId(),
    },
    header: {
      version: 1.0,
      created_by: getAuthCognitoId(),
      request_id: "",
      message_name: "insurancetype_questions-R",
      message_type: "Q",
      message_uuid: "",
      correlation_uuid: "",
      created_timestamp: "2020-01-01000000.000",
      service_completion_status_code: "",
    },
  };
};

export const getUserInsuranceOptionPayloadTemplate = (
  insuranceType,
  otherData
) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "user_lead-C",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      ...otherData,
      lead_type: "Customer Initiated",
      insurance_type: insuranceType,
    },
  };
};
export const getUserLoanProviderPayloadTemplate = (id) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "lender_R-All",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      cognito_user_id: getAuthCognitoId(),
    },
  };
};
export const createVaultPinPayloadTemplate = (pin) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "user_asset_vault_pin-C",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      cognito_user_id: getAuthCognitoId(),
      vault_pin: pin,
    },
  };
};
export const verificationPinPayloadTemplate = (pin) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "user_asset_vault_pin-V",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      cognito_user_id: getAuthCognitoId(),
      vault_pin: pin,
    },
  };
};
export const forgetVaultPinPayloadTemplate = (email, phone_number) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "forgot_vault_pin-C",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      cognito_user_id: getAuthCognitoId(),
      email,
      phone_number,
    },
  };
};
export const forgetOtpVerificationPayloadTemplate = (otp) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "forgot_vault_pin-V",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      cognito_user_id: getAuthCognitoId(),
      otp,
    },
  };
};

export const getNotificationPayloadTemplate = (start) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "user_notifications-R",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      limit: 999,
      start: start,
      cognito_user_id: getAuthCognitoId(),
    },
  };
};

export const readAllNotificationPayloadTemplate = () => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "user_notification-CL",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: { cognito_user_id: getAuthCognitoId() },
  };
};

export const readUpdateNotificationPayloadTemplate = (sequence_id) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "user_notification-U",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: { sequence_id },
  };
};
export const getInsurancePolicyListPayloadTemplate = (limit, start) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "user_insurance_basic_details-R",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      // limit: 5,
      // start: 0,
    },
  };
};

export const addPolicyPayloadTemplate = (type, payload) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "user_insurance-C",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      insurance_type: type,
      cognito_user_id: getAuthCognitoId(),
      ...payload,
    },
  };
};
export const createVerificationPayloadTemplate = (payload) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "insurance_verification-C",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: payload,
  };
};

export const verificationListPayloadTemplate = () => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "insurance_verification_list-R",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {},
  };
};

export const getIdCardCategoryPayloadTemplate = () => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "id_card_category-R",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {},
  };
};

export const readIdCardPayloadTemplate = () => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "id_card_category-R",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {},
  };
};

export const deleteIdCardPayloadTemplate = (sequence_id) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "id_card-D",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: { sequence_id },
  };
};

export const createIdCardPayloadTemplate = (id_card_picture_loc) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "id_card-C",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: { id_card_picture_loc }, //id_card_category
  };
};

export const choicesInsuranceMarketplacePayloadTemplate = (payload) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "user_lead-C",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: payload,
  };
};

export const deletePolicyCardPayloadTemplate = (sequence_id) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "user_insurance_policy-D",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: { sequence_id },
  };
};

export const viewPolicyCardPayloadTemplate = (sequence_id) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "user_insurance-R",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: { sequence_id },
  };
};
export const getPolicyIdCardCategoryPayloadTemplate = (sequence_id) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "id_card_category_list-R",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: { sequence_id },
  };
};

export const canopyLoginPayloadTemplate = (pull_id, type, insuranceType) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "external_source-C",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      reference_number: pull_id,
      page_source: type,
      insurance_type: insuranceType,
    },
  };
};

export const changePhoneOrEmailPayloadTemplate = (data) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "forgot_vault_pin-C",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      cognito_user_id: getAuthCognitoId(),
      ...data,
    },
  };
};

export const phoneOrEmailOtpVerificationPayloadTemplate = (otp) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "forgot_vault_pin-V",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      cognito_user_id: getAuthCognitoId(),
      otp,
    },
  };
};

export const getUserDetailsByLeadUUIDPayloadTemplate = (UUID) => {
  return {
    header: {
      message_name: "user_details_by_lead_uuid-R",
    },
    body: {
      lead_uuid: UUID,
    },
  };
};

export const getLeadDetailsByLeadUUID = (UUID) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "get_lead_details_by_lead_uuid-R",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      lead_uuid: UUID,
    },
  };
};

export const cancelInsuranceLeadPayloadTemplate = ({
  loan_number,
  first_name,
  last_name,
  lender_id,
  insurance_type,
  reason,
}) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "cancel_lead_creation-U",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      loan_number: "HL-654347-JP",
      first_name: "Sanjay Makwana",
      last_name: "Galt",
      lender_id: 1,
      insurance_type: "home",
      reason: "reason",
    },
  };
};

export const connectWebQuery = (body) => {
  return {
    header: {
      message_name: "web_queries-C",
    },
    body: body,
  };
};
export const userRegisterPayloadTemplate = (body) => {
  return {
    header: {
      message_name: "register_user-C",
    },
    body: body,
  };
};
export const verificationInsuranceByTypePayloadTemplate = (insurance_type) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "user_insurance_by_type-R",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      insurance_type,
    },
  };
};

export const contactLeadTemplate = (data) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "contact_lead-C",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      quote_id: data?.quote_id,
    },
  };
};

export const getCompareQuoteRecordTemplate = (sequence_id) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "user_lead_compare_agents_quote-R",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      sequence_id: sequence_id,
    },
  };
};

export const getFirebaseCustomerTokenTemplate = (sequence_id) => {
  return {
    header: {
      message_uuid: "",
      correlation_uuid: "",
      message_name: "user_lead_compare_agents_quote-R",
      message_type: "Q",
      request_id: "",
      version: "1.0",
      service_completion_status_code: "",
      created_by: getAuthCognitoId(),
      created_timestamp: "2020-01-01000000.000",
    },
    body: {
      sequence_id: sequence_id,
    },
  };
};
