import { createContext, useContext, useEffect, useState } from "react";
import { useLocalStorage } from "../http/services/local-storage";
import {
  buildCognitoCreds,
  cognitoSignOut,
} from "../http/services/cognito.service";
import { getProfileDetailsApi } from "../http/services/user.service";
import { getProfileDetailsPayloadTemplate } from "../http/services/api-payload-prepare";
import { enqueueSnackbar } from "notistack";
import { getAuthAwsCred, getAuthCognitoId, getAuthUser, useLocalStorageService } from "../http/services/local-storage-service";
// import { auth } from "../pages/Chats/firebase";
// import { signInWithEmailAndPassword } from "firebase/auth";

export const AuthContext = createContext({
  authUser: null,
  setAuthUser: () => {},
});

export const useAuth = () => {
  const { authUser, setAuthUser } = useContext(AuthContext);
  const { getItem, setItem, removeItem } = useLocalStorage();
  const { setLocalStorageRecord } = useLocalStorageService();
  let expirationDuration = null;
  let tokenExpirationTimer = null;
  const login = async (user) => {
    try {
      setLocalStorageRecord("authUser", JSON.stringify(user));
      setLocalStorageRecord("authCognitoId", user.signInUserSession.getIdToken().payload.sub);
      const awsCred = await buildCognitoCreds(
        user.signInUserSession.getIdToken().getJwtToken()
      );
      setLocalStorageRecord("authAwsCred", JSON.stringify(awsCred));
      const profilePayload = getProfileDetailsPayloadTemplate();
      let validateUser = await getProfileDetailsApi(profilePayload);
      setLocalStorageRecord("authUserValidated", false);
      
      if (validateUser.code === 209) {
        // const loginFireBase = await signInWithEmailAndPassword(
        //   auth,
        //   `${validateUser?.user?.sequence_id}@firebase.com`,
        //   `${validateUser?.user?.email?.slice(
        //     0,
        //     5
        //   )}${validateUser?.user?.phone_number?.slice(0, 5)}`
        // );
        // console.log("loginFireBase", loginFireBase);
        setLocalStorageRecord("authUserValidated", true);
        setLocalStorageRecord("authUserProfile", JSON.stringify(validateUser.user));
      }
      startAutoLogoutTimer();

      setAuthUser({
        ...user,
        authUserProfile: validateUser.user,
        authUserValidated: validateUser.code === 209,
      });
      enqueueSnackbar("Login successful.!", {
        variant: "success",
      });
    } catch (err) {
      console.log("err", err);

      const customLogoutMessage = "login session expired.!";
      logout(customLogoutMessage, "error");
    }
  };

  const startAutoLogoutTimer = () => {
    let awsCred = getAuthAwsCred();
    if (awsCred) {
      awsCred = JSON.parse(awsCred);
      expirationDuration =
        new Date(awsCred._tokenExpirationDate).getTime() - new Date().getTime();
      sessionAutoLogoutTimer(expirationDuration);
    }
  };

  const logout = (customLogoutMessage = null, customVariant = null) => {
    removeItem("data");
    // removeItem("authUser");
    // removeItem("authAwsCred");
    // removeItem("authCognitoId");
    // removeItem("authUserValidated");
    // removeItem("authUserProfile");
    // removeItem("enterVaultPin");
    const otherItemsToRemove = Object.keys(localStorage).filter(
      (item) =>
        item.includes("aws.cognito.identity") ||
        item.includes("CognitoIdentityServiceProvider")
    );
    otherItemsToRemove.forEach((k) => localStorage.removeItem(k));
    if (tokenExpirationTimer) {
      clearTimeout(tokenExpirationTimer);
      tokenExpirationTimer = null;
    }
    cognitoSignOut().then(() => {});
    setAuthUser(null);
    enqueueSnackbar(customLogoutMessage ?? "Logout successful.!", {
      variant: customVariant ?? "success",
    });
  };

  const sessionAutoLogoutTimer = (expirationDuration) => {
    // if (!tokenExpirationTimer) {
    //   tokenExpirationTimer = setTimeout(() => {
    //     logout("Token expired, Auto logout successful.!", "warning");
    //   }, expirationDuration - 300000);
    // }
  };

  const sessionAutoLogoutTimerClear = () => {
    if (tokenExpirationTimer) {
      clearTimeout(tokenExpirationTimer);
      tokenExpirationTimer = null;
    }
  };

  const getCognitoId = () => {
    return authUser;
  };

  const isLoggedIn = () =>
    !!getAuthUser()&&
    !!getAuthAwsCred() &&
    !!getAuthCognitoId();

  return {
    isLoggedIn,
    login,
    logout,
    getCognitoId,
    startAutoLogoutTimer,
    sessionAutoLogoutTimerClear,
    authUser,
    setAuthUser,
  };
};
