import {
  Button,
  Checkbox,
  CircularProgress,
  FormHelperText,
  Grid,
  Modal,
} from "@mui/material";
import logo from "../assets/images/logo.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { environment } from "../environment/environment";
import { loaderEnd, loaderStart } from "../redux/slices/loader";
import jvnLogo from "../assets/images/jvn.png";
import kind_logo from "../assets/images/kind_logo.svg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import documentIcon from "../assets/images/policy_document-icon.svg";
import deleteIcon from "../assets/images/delete_policy.svg";
import FilesDragAndDrop from "../comman/dragDrop/FilesDragAndDrop";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import logoYoloh from "../assets/images/yolohblue.svg";
import {
  addPolicyPayloadTemplate,
  canopyLoginPayloadTemplate,
  choicesInsuranceMarketplacePayloadTemplate,
  createVerificationPayloadTemplate,
} from "../http/services/api-payload-prepare";
import {
  canopyLoginExternalSource,
  getPolicyLists,
  insurancePreFillLead,
  insuranceVerification,
} from "../http/services/lead.service";
import { useNavigate } from "react-router";
import { useAuth } from "../context/AuthContext";
import { enqueueSnackbar } from "notistack";
const uploadPolicyDocument = ["application/pdf"];
const MAX_FILE_SIZE = 1050; // 10MB
const VerifyInsuranceDialog = ({ isShow, onClose, record }) => {
  const [selectType, setSelectType] = useState("");
  const navigate = useNavigate();
  const { authUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState({});
  const [checked, setChecked] = useState(false);
  const [teamsAndCondition, setTeamsAndCondition] = useState(false);
  const [uploadPolicyError, setUploadPolicyError] = useState("");
  const [insuranceLoginPullId, setInsuranceLoginPullId] = useState("");

  const onSubmitInsuranceMarketplace = async () => {
    setLoading(true);
    const preQuestion = {
      house_purchase_status: "",
      here_for: "",
      other_lender_name: "",
      other_lender_website: "",
    };
    const payload = {
      zip: record?.lead_details?.propertyZip ?? "",
      city: record?.lead_details?.propertyCity ?? "",
      state: record?.lead_details?.propertyState ?? "",
      status: "DRAFT",
      lead_type: "Customer Initiated",
      addrress_line1: record?.lead_details?.propertyAddressLine ?? "",
      addrress_line2: record?.lead_details?.communicationAddressLines ?? "",
      insurance_type:
        answer?.combine_insurance === "yes"
          ? record?.lead_details?.insuranceType?.id === "home"
            ? "autoandhome"
            : "autoandcondo"
          : record?.lead_details?.insuranceType?.id,
      country: "",
      application_type: "LOAN_REFERENCE",
      lender_sequence_id: record?.lead_details?.lenderSequenceId,
      loan_reference_number: record?.lead_details?.loanReferenceNumber ?? "",
      lead_pre_questionnaire: JSON?.stringify(preQuestion),
    };
    const submitHomeInsurancePayload =
      choicesInsuranceMarketplacePayloadTemplate(payload);
    insurancePreFillLead(submitHomeInsurancePayload)
      .then((data) => {
        if (data && data?.sequence_id && data?.lead_details) {
          const { lead_details, sequence_id, message } = data;
          // enqueueSnackbar(message, { variant: "success" });
          onClose();
          if (answer?.uploadDocument?.name) {
            navigate("/");
          } else {
            navigate(
              `/insurance/${
                answer?.combine_insurance === "yes"
                  ? record?.lead_details?.insuranceType?.id === "home"
                    ? "autoandhome"
                    : "autoandcondo"
                  : record?.lead_details?.insuranceType?.id
              }`,
              {
                state: {
                  lead_details,
                  sequenceId: sequence_id,
                  // insurance_type: record?.insuranceType,
                  insurance_type: {
                    id:
                      answer?.combine_insurance === "yes"
                        ? record?.lead_details?.insuranceType?.id === "home"
                          ? "autoandhome"
                          : "autoandcondo"
                        : record?.lead_details?.insuranceType?.id,
                    title:
                      answer?.combine_insurance === "yes"
                        ? record?.lead_details?.insuranceType?.id === "home"
                          ? "autoandhome"
                          : "autoandcondo"
                        : record?.lead_details?.insuranceType?.title,
                  },
                },
              }
            );
          }
        } else if (data && data?.error) {
          enqueueSnackbar(data?.error, { variant: "error" });
        } else {
          enqueueSnackbar("Something went wrong.!", { variant: "error" });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong.!", { variant: "error" });
      })
      .finally(() => setLoading(false));
  };
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const onUploadDocument = async () => {
    const payload = {
      // insurance_type,
      lender_sequence_id: record?.lead_details?.lenderSequenceId ?? 0,
      loan_reference_number: record?.lead_details?.loanReferenceNumber ?? "",
      other_lender_name: "",
      lender_email: record?.lead_details?.lenderEmail ?? "",
      // other_lender_email: answer?.lender_email ?? "",
      cannopy_login_response: {},
    };
    const base64 = await convertToBase64(answer?.uploadDocument);
    setLoading(true);
    let insuranceType =
      answer?.combine_insurance === "yes"
        ? record?.lead_details?.insuranceType?.id === "home"
          ? "autoandhome"
          : "autoandcondo"
        : record?.lead_details?.insuranceType?.id;
    const addPolicyPayload = addPolicyPayloadTemplate(insuranceType, {
      insurance_documents: [base64],
    });
    getPolicyLists(addPolicyPayload)
      .then(async (data) => {
        if (data?.code === 201 && data?.sequenceId > 0) {
          await addVerificationRequest({
            ...payload,
            user_insurance_id: data?.sequenceId,
            email: authUser?.authUserProfile?.email,
          });
        }
      })
      .catch((err) => {});
    // .finally(() => setLoading(false));
  };

  const addVerificationRequest = (payload) => {
    setLoading(true);
    const verificationInsurancePayload =
      createVerificationPayloadTemplate(payload);
    insuranceVerification(verificationInsurancePayload)
      .then((data) => {
        if (data?.code === 201 && data?.message) {
          const { message } = data;
          setSelectType("verification");
        }
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };
  const onSubmitCanopyInsuranceLogin = async (pull_id) => {
    dispatch(loaderStart());
    let insuranceType =
      answer?.combine_insurance === "yes"
        ? record?.lead_details?.insuranceType?.id === "home"
          ? "autoandhome"
          : "autoandcondo"
        : record?.lead_details?.insuranceType?.id;
    const canopyLoginInsurancePayload = canopyLoginPayloadTemplate(
      pull_id,
      "voi",
      insuranceType
    );
    canopyLoginExternalSource(canopyLoginInsurancePayload)
      .then((data) => {
        if (data && data?.code === 201 && data?.message) {
          setSelectType("verification");
        } else if (data && data?.error) {
          enqueueSnackbar(data?.error, { variant: "error" });
        } else {
          enqueueSnackbar("Something went wrong.!", { variant: "error" });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong.!", { variant: "error" });
      })
      .finally(() => {
        dispatch(loaderEnd());
      });
  };
  const CanopyConnectBtn = ({ publicAlias }) => {
    const [handler, setHandler] = useState(null);
    useEffect(() => {
      if (!publicAlias) {
        return;
      }
      let canopyHandler = window?.CanopyConnect?.create({
        publicAlias,
      });
      setHandler(canopyHandler);
      canopyHandler.on("authenticationSuccess", (authResponse) => {
        dispatch(loaderStart());
        onSubmitCanopyInsuranceLogin(authResponse?.pull?.pull_id);
        setInsuranceLoginPullId(authResponse?.pull?.pull_id);
        // onClose();
        canopyHandler.close();
      });
      return () => {
        setHandler(null);
        canopyHandler.destroy();
      };
    }, [publicAlias]);
    if (!handler) {
      return (
        <Button disabled size="lg" as="a">
          Loading....
        </Button>
      );
    }

    return (
      <Button
        className="view_button"
        onClick={() => {
          handler.open();
        }}
      >
        Login to get Policy
      </Button>
    );
  };
  const onBackQuestion = () => {
    let type = "";
    // if (selectType === "upload_policy") {
    //   type = "upload_or_login_lead";
    // } else
    if (answer?.uploadDocument?.name && selectType === "verification") {
      type = "upload_policy";
    }
    // else if (selectType === "verification") {
    //   type = "upload_or_login_lead";
    // }
    setSelectType(type);
  };
  return (
    <Modal
      open={isShow}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="main_modal_bg "
    >
      <div
        className={`${
          ["create_lead", "upload_policy", "verification"].includes(selectType)
            ? ""
            : "verify-model mx720"
        } main_modal model-contain-main-upload`}
      >
        {/* <div
          className={`d-flex ${
            selectType?.length > 0 ? "justify-center" : "justify-between"
          } align-items-center`}
        >
          {!selectType?.length > 0 && (
            <img
              alt="lander_logo"
              src={record?.lender === "kind" ? kind_logo : jvnLogo}
              className="logoMain logoinvert"
            />
          )}
          <img src={logo} alt="yoloh_logo" height={"33px"} width={"133px"} />
        </div> */}
        <div className="modal_bg bg-dark-blue">
          <div className={`modal_header verify-model-header`}>
            <div className="cursor-pointer">
              {selectType?.length > 0 ? (
                <ArrowBackIosIcon onClick={onBackQuestion} />
              ) : (
                <div></div>
              )}
            </div>
            <p>
              {selectType === "upload_or_login_lead"
                ? "VERIFY Your Insurance"
                : ["create_lead", "upload_policy", "verification"].includes(
                    selectType
                  )
                ? ""
                : "VERIFY or BUY Insurance"}
            </p>
            {["create_lead", "upload_policy", "verification"].includes(
              selectType
            ) && <img src={logoYoloh} alt="" className="modal_logo" />}
            <div className="cursor-pointer text-end">
              <CloseIcon onClick={onClose} />
            </div>
          </div>
          <div
            className="model-contain-main-responsive model-contain-main-responsive-upload"
            style={{ padding: `50px` }}
          >
            <div
              className="model-contain noflex"
              id="model-insurance-marketplace"
            >
              {selectType === "upload_or_login_lead" ? (
                <Grid container spacing={6}>
                  <Grid
                    xs={12}
                    sm={6}
                    md={6}
                    item={true}
                    style={{ display: "flex" }}
                  >
                    <div className="p-40 verify-insurance-policy">
                      <p>
                        With your consent, we can likely pull in your policy
                        from your insurance carrier by selecting from our
                        carrier list
                      </p>
                      <CanopyConnectBtn
                        publicAlias={
                          environment?.canopy_connect?.canopy_public_alias
                        }
                      />
                    </div>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    md={6}
                    item={true}
                    style={{ display: "flex" }}
                  >
                    <div className="p-40 verify-insurance-policy">
                      <p>
                        Or, you can simply upload the policy yourself, including
                        if your carrier was not shown in our list
                      </p>
                      <Button
                        className="view_button"
                        style={{ backgroundColor: "yellow !important" }}
                        onClick={() => setSelectType("upload_policy")}
                      >
                        Upload Policy
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              ) : selectType === "create_lead" ? (
                <>
                  <h1
                    id="modal-modal-title"
                    className="white-color text-center"
                  >
                    {`Hey, do you want to combine ${
                      record?.lead_details?.insuranceType?.id === "home"
                        ? "Auto and Home"
                        : record?.lead_details?.insuranceType?.id?.id ===
                          "condo"
                        ? "Auto and Condo"
                        : record?.lead_details?.insuranceType?.title
                    } 
                      Insurance? It could lead to substantial savings.`}
                  </h1>
                  <div className="question-contain justify-center">
                    <div className="survey_card_header">
                      <input
                        id="applicable_yes"
                        type="radio"
                        value="yes"
                        name="combine_insurance"
                        checked={answer?.combine_insurance === "yes"}
                        className="survey_option_radio white-border bg-dark-blue"
                        onChange={(e) => {
                          setAnswer((pre) => {
                            return { ...pre, combine_insurance: "yes" };
                          });
                        }}
                      />
                      <label htmlFor="applicable_yes" className="white-color">
                        Yes
                      </label>
                    </div>
                    <div className="survey_card_header">
                      <input
                        id="applicable_no"
                        type="radio"
                        value="no"
                        checked={answer?.combine_insurance === "no"}
                        name="combine_insurance"
                        className="survey_option_radio white-border bg-dark-blue"
                        onChange={(e) => {
                          setAnswer((pre) => {
                            return { ...pre, combine_insurance: "no" };
                          });
                        }}
                      />
                      <label htmlFor="applicable_no" className="white-color">
                        No
                      </label>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "40px",
                    }}
                  >
                    <Button
                      className="view_button"
                      disabled={
                        !answer?.combine_insurance?.length > 0 || loading
                      }
                      style={{ width: "200px", height: 60 }}
                      onClick={onSubmitInsuranceMarketplace}
                    >
                      {loading && (
                        <>
                          <CircularProgress color="inherit" size={20} />
                          &nbsp; &nbsp;
                        </>
                      )}
                      Continue
                    </Button>
                  </div>
                </>
              ) : selectType === "upload_policy" ? (
                <>
                  <h1
                    id="modal-modal-title"
                    className="white-color text-center"
                  >
                    Upload your Policy
                  </h1>
                  {answer?.uploadDocument?.name ? (
                    <div className="upload_policy bg-white">
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <img alt="document-icon" src={documentIcon} />
                        <span
                          className="file-link"
                          style={{
                            fontFamily: "jokker-medium",
                            fontSize: "18px",
                          }}
                        >
                          {answer?.uploadDocument?.name}
                        </span>
                      </div>

                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          setAnswer((pre) => {
                            return { ...pre, uploadDocument: null };
                          });
                        }}
                      >
                        <img alt="delete-icon" src={deleteIcon} />
                      </div>
                    </div>
                  ) : (
                    <>
                      <FilesDragAndDrop
                        onUpload={(files) => {
                          if (uploadPolicyDocument?.includes(files?.type)) {
                            const fileSizeKiloBytes = files?.size / 1024;
                            if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                              setAnswer((pre) => {
                                return { ...pre, uploadDocument: null };
                              });
                              setUploadPolicyError(
                                "Please upload less than 1MB file size"
                              );
                            } else {
                              setUploadPolicyError("");
                              setAnswer((preAnswer) => {
                                return {
                                  ...preAnswer,
                                  uploadDocument: files,
                                };
                              });
                            }
                          } else {
                            setAnswer((pre) => {
                              return { ...pre, uploadDocument: null };
                            });
                            setUploadPolicyError(
                              "Uploaded file must be .pdf format"
                            );
                          }
                        }}
                        count={1}
                        name={"uploadDocument"}
                        formats={["pdf"]}
                        defaultFile={""}
                        message={uploadPolicyError}
                        setMessage={setUploadPolicyError}
                        style={{
                          minHeight: "87px",
                          borderRadius: "12px",
                          flexDirection: "row",
                          gap: "10px",
                          marginTop: "40px",
                          borderColor: "#fff",
                          color: "#fff",
                        }}
                        accept="application/pdf"
                      />
                      <FormHelperText
                        className="doc_error"
                        error={!!uploadPolicyError?.text}
                        variant="outlined"
                      >
                        {uploadPolicyError?.text && (
                          <InfoOutlinedIcon color="error" size="small" />
                        )}
                        <span>{uploadPolicyError?.text}</span>
                      </FormHelperText>
                    </>
                  )}
                  <div className="checkbox-contain">
                    <Checkbox
                      checked={checked}
                      color="turquoise"
                      onChange={(e) => setChecked(e?.target?.checked)}
                    />
                    <p className="white-color">
                      I am solely responsible for the information and documents
                      I provide, YOLOH is not liable for any errors, frauds or
                      misrepresentations.
                    </p>
                  </div>
                  <button
                    className="existingInsuranceLoginBtn mt-20 w-100"
                    disabled={
                      !answer?.uploadDocument?.name || !checked || loading
                    }
                    onClick={() => onUploadDocument()}
                  >
                    Upload Policy{" "}
                    {loading && (
                      <>
                        <CircularProgress color="inherit" size={20} />
                      </>
                    )}
                  </button>
                </>
              ) : selectType === "verification" ? (
                <div className="model-contain-main-responsive modal_body">
                  <div
                    className="model-contain"
                    id="model-insurance-marketplace"
                  >
                    <p className="login-link-message text-align-center">
                      Sit back and relax. We will now provide your lender with
                      your proof of insurance (required for loan closing). You
                      will be notified if any changes are required.
                    </p>
                    <p className="login-link-message text-align-center mt-30">
                      We have also stored your policy in your Policy Vault.
                      Thanks for choosing YOLOH!
                    </p>
                    <div style={{ textAlign: "center", marginTop: "40px" }}>
                      <Button
                        className="view_button"
                        style={{ width: "105px", height: "61px" }}
                        onClick={() => {
                          onClose();
                          navigate("/overview", { state: { isUpdate: true } });
                        }}
                      >
                        Ok
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <Grid container>
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    item={true}
                    // style={{ display: "flex" }}
                  >
                    <div className="verify-insurance-content">
                      <p>
                        If you already have insurance on the loan's property,
                        share the policy here for your lender to verify
                      </p>
                      <Button
                        className="view_button submit_button"
                        style={{ width: "199px" }}
                        // onClick={() => setSelectType("upload_or_login_lead")}
                        onClick={() => setSelectType("upload_policy")}
                      >
                        Submit Policy
                      </Button>
                    </div>
                    <div className="verify-insurance-content pt-30">
                      <p>
                        If needing insurance, use our speedy and secure process
                        to get competitive quotes
                      </p>
                      <Button
                        className="view_button"
                        style={{ width: "199px" }}
                        onClick={() => setSelectType("create_lead")}
                      >
                        Get Great Quotes
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VerifyInsuranceDialog;
