import React from "react";
import { Navigate } from "react-router";
import DrawerIndex from "../pages/DrawerIndex";
import {
  getAuthAwsCred,
  getAuthCognitoId,
  getAuthUser,
} from "../http/services/local-storage-service";

const PrivateRoute = ({ isLayout, children }) => {
  return getAuthUser() && getAuthAwsCred() && getAuthCognitoId() ? (
    <>{isLayout ? <DrawerIndex>{children}</DrawerIndex> : children}</>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
